<template>
  <div class="toggle_menu">
    <div
      v-for="(main, idx) in menuList"
      :key="`menu_main_${idx}`"
      class="toggle_menu_item"
    >
      <button
        class="toggle_menu_item_btn"
        @click="mainActiveIdx = idx; subActiveIdx = 0"
      >
        <span class="p">
          {{ convertToEnglish(main.name, main.name_en) }}
        </span>
        <NextArrow
          class="toggle_arrow_icon"
          :class="{ active: mainActiveIdx === idx }"
          fillColor="#ccc"
        />
      </button>
      <VueSlideToggle :open="mainActiveIdx === idx" :duration="500">
        <ul class="toggle_menu_sub">
          <li
            v-for="(sub, subIdx) in main.list"
            :key="`menu_sub_${subIdx}`"
            class="toggle_menu_sub_item small"
            :class="{ active: mainSelectedIdx === idx && subSelectedIdx === subIdx }"
            @click="subActiveIdx = subIdx; chooseCertainMenu(mainActiveIdx, subActiveIdx)"
          >
            {{ convertToEnglish(sub.name, sub.name_en) }}
          </li>
        </ul>
      </VueSlideToggle>
    </div>
  </div>
</template>

<script>
import { VueSlideToggle } from 'vue-slide-toggle';
import NextArrow from '@/components/icon/NextArrow.vue';
import languageMixin from '@/mixins/languageMixin';
import searchMixin from '@/mixins/searchMixin';

export default {
  name: 'ToggleMenu',
  mixins: [languageMixin, searchMixin],
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
    routeData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    VueSlideToggle,
    NextArrow,
  },
  data() {
    return {
      /** 顯示開啟、效果相關的idx */
      mainActiveIdx: 0,
      subActiveIdx: 0,

      /** 確定選取的idx */
      mainSelectedIdx: 0,
      subSelectedIdx: 0,
    };
  },
  methods: {
    chooseCertainMenu(mainIdx, subIdx) {
      this.mainSelectedIdx = mainIdx;
      this.subSelectedIdx = subIdx;

      const routeData = {
        lv1: this.menuList?.[mainIdx].name,
        lv2: this.menuList?.[mainIdx]?.list?.[subIdx].name,
        page: 1,
      };
      this.setRouteData(routeData);
    },
  },
  watch: {
    routeData: {
      immediate: true,
      deep: true,
      handler(obj) {
        this.mainActiveIdx = this.menuList.findIndex((main) => main.name === obj.lv1);
        this.mainSelectedIdx = this.menuList.findIndex((main) => main.name === obj.lv1);

        this.subActiveIdx = this.menuList[this.mainActiveIdx].list
          .findIndex((sub) => sub.name === obj.lv2);
        this.subSelectedIdx = this.menuList[this.mainActiveIdx].list
          .findIndex((sub) => sub.name === obj.lv2);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.toggle_menu {
  .toggle_arrow_icon {
    transform: rotate(90deg);
    &.active { transform: rotate(270deg); }
  }
  &_item {
    &_btn {
      padding: 0.5rem 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $color-medium_gray;
    }
  }
  .toggle_menu_sub {
    &_item {
      padding: 0.5rem 0;
      padding-left: 2rem;
      background-color: $color-light_gray;
      cursor: pointer;
      &.active {
        color: #fff;
        background-color: $color-red;
        position: relative;
        &::before {
          content: '';
          width: 6px;
          height: 6px;
          background: #fff;
          position: absolute;
          top: 50%;
          left: 5%;
          transform: translate(0, -50%);
        }
      }
      @media screen and (min-width: 1024px) {
        &:hover:not(.active) {
          position: relative;
          background-color: $color-gray-1;
          &::before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: 5%;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
}
</style>
