<template>
  <div
    class="products"
    ref="scrollTarget"
  >
    <Banner
      ref="banner"
      title="PRODUCTS"
      subtitle="產品介紹"
      :img="bannerImg"
    />
    <div class="container w1400">
      <div class="product_menu-mobile">
        <Select
          :customArrow="true"
          :format="inputFormat.mainSelect"
          :selected.sync="formData.main"
        />
        <Select
          :customArrow="true"
          :format="inputFormat.subSelect"
          :selected.sync="formData.sub"
        />
      </div>
      <div class="product_menu">
        <ToggleMenu
          v-if="menuList.length"
          :menuList="menuList"
          :routeData="getRouteData()"
          :style="{ top: `${headerHeight + 20}px` }"
        />
      </div>
      <div class="product_list" ref="productList">
        <div class="product_list_series h3 txt-main suffix-redCube">{{ seriesNameEn }}</div>
        <div class="h6">{{ seriesNameCn }} / {{ seriesSubName}}</div>
        <div class="product_list_wrapper">
          <ProductItem
            v-for="(product, idx) in products"
            :key="`product_${idx}`"
            :series="{ main: seriesNameCn, sub: seriesSubName }"
            :product="product"
          />
        </div>
        <Pagination
          :page="currentPage"
          :totalPage="totalPage"
          @updatePage="updatePage"
        />
      </div>
    </div>
    <PdfDownloader
      :class="{ fixed: isFixed }"
    />

    <!-- 手機版：往下滾動時，上方提示目前選取的主、子系列，並有go top的功能 -->
    <div
      class="helper_hint txt-center p"
      :class="{ fixed: isHelperHintFixed }"
      :style="{ top: `${headerHeight}px` }"
      @click="scrollToTop()"
    >
      <span>{{ convertToEnglish(seriesNameCn, seriesNameEn) }}</span>
      <span v-show="seriesSubName">
        /{{ seriesSubName }}
      </span>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/product.scss';
import bannerImg from '@/assets/imgs/banner/banner_products.png';
import Banner from '@/components/banner/Index.vue';
import ToggleMenu from '@/components/toggleMenu/Index.vue';
import ProductItem from '@/components/productItem/Index.vue';
import Select from '@/components/form/Select.vue';
import Pagination from '@/components/pagination/Index.vue';
import PdfDownloader from '@/components/pdfDownloader/Index.vue';

import publicMixin from '@/mixins/publicMixin';
import languageMixin from '@/mixins/languageMixin';
import searchMixin from '@/mixins/searchMixin';
import { isEqual } from '@/lib/lodash';
import requestApi from '@/lib/http';
import { formatProduct } from '@/utils/formatResponse';

export default {
  name: 'Products',
  mixins: [publicMixin, languageMixin, searchMixin],
  components: {
    Banner,
    ToggleMenu,
    ProductItem,
    Select,
    Pagination,
    PdfDownloader,
  },
  data() {
    return {
      bannerImg,
      allSerieses: [],
      seriesesInOrder: [],
      products: [],
      menuSelected: {},
      formData: {
        main: null,
        sub: null,
      },
      menuMobileInit: false,
      totalPage: 0,
      isFixed: true,
      isHelperHintFixed: false,
    };
  },
  computed: {
    headerHeight() {
      return this.$store.state.site.headerHeight;
    },
    menuList() {
      return this.$store.state.site.menuList;
    },
    seriesNameCn() {
      return this.getRouteData().lv1;
    },
    seriesNameEn() {
      return this.menuList.find((main) => main.name === this.seriesNameCn)?.name_en;
    },
    seriesSubName() {
      const mainList = this.menuList.find((main) => main?.name === this.getRouteData().lv1)?.list;
      const subTarget = mainList?.find((sub) => sub?.name === this.getRouteData().lv2);

      return this.convertToEnglish(subTarget?.name, subTarget?.name_en);
    },
    mainOptions() {
      return this.menuList?.map((main) => ({
        label: this.convertToEnglish(main.name, main.name_en),
        value: this.convertToEnglish(main.name, main.name_en),
        labelName: main.name, // 中文名稱對照用，傳給api的值
      })) || [];
    },
    mainSelectedLabelName() {
      return this.mainOptions?.find((option) => option.value === this.formData.main)?.labelName;
    },
    subOptions() {
      return this.menuList
        ?.filter((main) => main.name === this.mainSelectedLabelName)?.[0]?.list
        ?.map((sub) => ({
          label: this.convertToEnglish(sub.name, sub.name_en),
          value: this.convertToEnglish(sub.name, sub.name_en),
          labelName: sub.name, // 中文名稱對照用，傳給api的值
        })) || [];
    },
    subSelectedLabelName() {
      return this.subOptions?.find((option) => option.value === this.formData.sub)?.labelName;
    },
    inputFormat() {
      return {
        mainSelect: {
          placeholder: this.convertToEnglish('請選擇主系列', 'Select Main series'),
          options: this.mainOptions,
        },
        subSelect: {
          placeholder: this.convertToEnglish('請選擇子系列', 'Select Sub series'),
          options: this.subOptions,
        },
      };
    },
    currentPage() {
      return Number(this.$route?.query?.page, 10) || 1;
    },
  },
  methods: {
    updatePage(page) {
      if (!isEqual(Number(this.$route.query.page, 10), Number(page, 10))) {
        this.$router.push({ query: { ...this.$route.query, page } });
      }
    },
    async getData(routeData) {
      const { lv1, lv2, page } = routeData;

      const perPage = 12;
      const skip = (page - 1) * perPage;

      const { entries: data, total } = await requestApi('common.getProducts', {
        filter: {
          lv1,
          lv2,
        },
        sort: {
          _o: 1, // 按後台商品列表顯示順序排列
        },
        limit: perPage, // 每頁幾筆資料
        skip, // 跳過幾筆資料後開始顯示
      });
      this.products = data.map(formatProduct).map((product) => ({
        ...product,
        lv1_en: this.seriesNameEn,
      }));
      this.totalPage = Math.ceil(total / perPage);
    },
    scrollHandler() {
      const screenHeight = window.screen.height;
      const bannerHeight = this.$refs.banner.$el.clientHeight;

      // 凍結效果：型錄下載
      (() => {
        const target = this.$refs.scrollTarget.getBoundingClientRect();
        if (window.scrollY + (screenHeight * 1.05) >= bannerHeight + target.height) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      })();

      // 凍結效果：手機版系列選取值
      (() => {
        const target = this.$refs.productList.getBoundingClientRect();
        if (target.top - this.headerHeight <= 0) {
          this.isHelperHintFixed = true;
        } else {
          this.isHelperHintFixed = false;
        }
      })();
    },
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.getData(this.getRouteData());
        }
      },
    },
    'formData.main': {
      handler() {
        if (!this.menuMobileInit) this.menuMobileInit = true;
        else this.formData.sub = '';
      },
    },
    'formData.sub': {
      handler(val) {
        if (val) {
          this.setRouteData({
            lv1: this.mainSelectedLabelName,
            lv2: this.subSelectedLabelName,
            page: 1,
          });
        }
      },
    },
  },
};
</script>
