import { isEqual } from '@/lib/lodash';

export default {
  methods: {
    getRouteData() {
      const { params: { lv1, lv2 }, query: { page = 1 } } = this.$route;
      return { lv1, lv2, page };
    },
    setRouteData(routeData) {
      if (!isEqual(routeData, this.getRouteData())) {
        const { lv1, lv2, page = 1 } = routeData;
        const newRoute = {
          name: 'Products',
          params: { lv1, lv2 },
          query: { ...this.$route.query, page },
        };
        this.$router.push(newRoute);
      }
    },
  },

};
